<template>
    <section class="section">
        <div class="container">
            <div class="introtext">
                <BulbIcon />
                {{ $t('Settings intro text') }}
            </div>
            <router-view />
        </div>
    </section>
</template>

<script>
    import BulbIcon from '@/components/icons/Bulb.vue'

    export default {
        name: 'Settings',
        components: { BulbIcon },
        mounted() {
            this.$store.dispatch('settings/fetchSections')
        }
    }
</script>
